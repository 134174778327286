@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    background-color: var(--color-foreground-inverted-default);
    padding: var(--spacing-core-4);
    color: var(--color-foreground-primary-default);
    max-width: var(--grid-width-max);
    margin: 0 auto;

    a {
        text-decoration: none;
        color: var(--color-foreground-primary-default);
    }

    .links {
        display: none;
        align-items: center;
        gap: var(--spacing-core-10);
        margin: 0;

        > li {
            list-style-type: none;
        }

        @include mq($from: medium) {
            display: flex;
        }
    }

    img {
        height: 42px;
    }
}

.mobileNavButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: var(--sizing-core-2);

    @include mq($from: medium) {
        display: none;
    }
}

.logoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-core-2);
}
