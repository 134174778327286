@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.mobileOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-accent-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: slideInFromRight 0.3s ease-out;
    animation-fill-mode: forwards;

    &.closing {
        animation: slideOutToRight 0.3s ease-out;
        animation-fill-mode: forwards;
    }
}

.mobileClose {
    position: absolute;
    top: var(--spacing-core-4);
    right: var(--spacing-core-4);
    background: none;
    border: none;
    cursor: pointer;
    padding: var(--sizing-core-2);
    color: var(--color-foreground-inverted-default);
}

.mobileLinks {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    padding: var(--sizing-core-4);
    margin: 0;
    gap: var(--sizing-core-4);

    li {
        margin: 0;
        padding-bottom: var(--sizing-core-4);

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-foreground-inverted-default);
        }

        a, p {
            font-size: var(--sizing-core-8);
            font-weight: 800;
            line-height: 1;
            color: var(--color-foreground-inverted-default);
            text-decoration: none;
            transition: color 0.2s ease;

            &:hover {
                color: var(--color-foreground-primary-default);
            }
        }
    }
}
